<template>
    <div>
        <div class="row row--15">
            <div class="col-lg-12">
                <ul class="nav nav-pills rwt-portfolio-filter filter-button-default liststyle mb--20"
                    id="pills-tab"
                    role="tablist"
                    data-aos="fade-up">
                    <li class="nav-item list-item"
                        role="presentation"
                        v-for="(filter, index) in filters"
                        :key="`tab-button-${filter.id}`">
                        <button class="nav-link"
                                :class="{'active': index === 0}"
                                :id="`pills-${filter.text}-tab`"
                                data-bs-toggle="pill"
                                :data-bs-target="`#pills-${filter.text}`"
                                type="button"
                                role="tab"
                                :aria-controls="`pills-${filter.text}`"
                                :aria-selected="index === activeTab ? 'true' : 'false'"
                                @click="activeTab = index">
                            {{ filter.text }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="rwt-gallery-area rn-section-gapBottom" v-for="(item,index) in galleryData" v-if="activeTab==0 || activeTab==index+1">
            <div class="container">
                <Gallery :gallery-data="item" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
            </div>
        </div>
        <!-- <div class="rwt-gallery-area rn-section-gapBottom" v-if="activeTab==0 || activeTab==2">
            <div class="container">
                <Gallery :gallery-data="galleryData2" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
            </div>
        </div> -->
        <!-- <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade"
                 :class="{'show active': index === 0}"
                 :id="`pills-${filter.text}`"
                 role="tabpanel"
                 :aria-labelledby="`pills-${filter.text}-tab`"
                 v-for="(filter, index) in filters"
                 :key="`tab-pane-${filter.id}`">
                <div class="row row--15">
                    <template v-if="filter.text === 'all'">
                        <div class="rwt-gallery-area rn-section-gapBottom">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 mb--20">
                                        <SectionTitle
                                            text-align="center"
                                            subtitle="Gallery With Lightbox"
                                            title="Gallery Style Two"
                                        />
                                    </div>
                                </div>
                                <Gallery :gallery-data="galleryData" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
                            </div>
                        </div>
                    </template>
                    <template v-if="filter.text !== 'all' && filter.text === portfolio.category"
                              v-for="(portfolio, index) in portfolioData">
                        <div :class="`${column} portfolio`">
                            <div class="rwt-card">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <figure class="card-image">
                                            <router-link :to="`/portfolio-details/${portfolio.id}`">
                                                <img class="img-fluid" :src="portfolio.portfolioImage" alt="Portfolio-01"/>
                                            </router-link>
                                        </figure>
                                        <router-link :to="`/portfolio-details/${portfolio.id}`" class="rwt-overlay"></router-link>
                                    </div>
                                    <div class="content">
                                        <h5 class="title mb--10">
                                            <router-link :to="`/portfolio-details/${portfolio.id}`">
                                                {{ portfolio.title }}
                                            </router-link>
                                        </h5>
                                        <span class="subtitle b2 text-capitalize">{{ portfolio.category }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div> -->
        <!-- <div class="row row--15">
            <div class="col-lg-12 text-center">
                <div class="rwt-load-more text-center mt--60" data-aos="fade-up">
                    <a :class="buttonClass" href="#">
                        Load More <Icon name="loader" icon-class="icon"/>
                    </a>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    import axios from 'axios'
import Icon from '../../icon/Icon'
import Gallery from "../gallery/Gallery"

    export default {
        name: 'Portfolio',
        components: {Icon,Gallery},
        props: {
            portfolioData: {},
            buttonClass: {
                type: String,
                default: 'btn btn-default btn-large btn-icon'
            },
            column: {
                type: String,
                default: 'col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
            }
        },
        data() {
            return {
                filters: [],
                galleryData: [],
                activeFilter: '',
                activeTab: 0
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                axios.get(`https://inside.tongjizhiyi.com/index.php?s=/api/passport/get_portfolio`).then(
					response => {
                        if(response.data.status==200){
                            this.filters = response.data.data.filters
                            this.galleryData = response.data.data.galleryData
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
            }

        }
    }
</script>