<template>
    <div>
        <div class="rwt-gallery-area mb--20" v-for="(item,index) in portfolioData" v-if="activeTab==0 || activeTab==index+1">
            <div class="container">
                <Gallery :gallery-data="item" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" data-aos="fade-up"/>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
import Icon from '../../icon/Icon'
import Gallery from "../gallery/Gallery2"

    export default {
        name: 'Portfolio',
        components: {Icon,Gallery},
        props: {
            portfolioData: [],
            buttonClass: {
                type: String,
                default: 'btn btn-default btn-large btn-icon'
            },
            column: {
                type: String,
                default: 'col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
            }
        },
        data() {
            return {
                filters: [],
                portfolioData: [],
                activeFilter: '',
                activeTab: 0
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                axios.get(this.$urls+'get_index_menus').then(
					response => {
                        
                        if(response.data.status==200){
                            this.portfolioData = response.data.data.galleryData
                            console.log('rr',this.portfolioData)
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
            }

        }
    }
</script>