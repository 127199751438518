import dataV from '@jiaminghi/data-view'
import 'animate.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import VueScrollActive from 'vue-scrollactive'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueTypedJs from 'vue-typed-js'
import App from './App.vue'
import './assets/scss/style.scss'
import router from './router'
// import 'swiper/css/swiper.css'
import '@/assets/fonts/font.css'
import Carousel3d from 'vue-carousel-3d'

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)
Vue.use(axios)
Vue.use(dataV)
Vue.use(VueAwesomeSwiper)
Vue.use(Carousel3d)
Vue.prototype.$urls='https://inside.tongjizhiyi.com/index.php?s=/guanwang/web/'

new Vue({
  router,
  created () {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app')
