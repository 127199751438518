<template>
    <div>
        <div class="rwt-gallery-area rn-section-gapBottom">
            <div class="container">
                <Gallery :gallery-data="galleryData" column="col-lg-2 col-md-6 col-sm-6 col-12 mt--30"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
import Gallery from "../gallery/Gallery3"

    export default {
        name: 'Portfolio',
        components: {Icon,Gallery,Gallery},
        props: {
            galleryData: {},
            buttonClass: {
                type: String,
                default: 'btn btn-default btn-large btn-icon'
            },
            column: {
                type: String,
                default: 'col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
            }
        },
        data() {
            return {
                galleryData: [],
            }
        },
        created() {
        },
    }
</script>