<template>
    <Layout>
        <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="1"
             :style="'background-image: url('+list.background+')'">
            <div>
            </div>
        </div>
        <div class="rn-service-area mt--30 mb--30">
            <div class="container">
                <div class="row no-gutters radius-10 align-items-center">
                    <div class="col-lg-12 col-xl-6 col-12 mb--30">
                        <SectionTitle
                            text-align="center"
                            :subtitle="list.list1_title"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                        <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                            :accordion-content="list.list1"
                        />
                    </div>
                    <div class="col-lg-12 col-xl-6 col-12 mb--30">
                        <div class="split-inner fade-up">
                            <SectionTitle
                            text-align="center"
                            :subtitle="list.list2_title"
                            data-aos="fade-up"
                            data-aos-delay="60"
                            />
                            <Accordion3
                            id="accordionExampleTwo"
                            data-aos="fade-up"
                            data-aos-delay="60"
                            :accordion-content="list.list2"
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="rn-service-area mb--30">
            <div class="container">
                <div class="row no-gutters radius-10 align-items-center">
                    <div class="col-lg-12 col-xl-6 col-12">
                        <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                    <div class="col-lg-12 col-xl-6 col-12">
                        <Accordion3
                            id="accordionExampleTwo"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="col-lg-12 col-xl-6 col-12">
                    <SectionTitle
                            text-align="center"
                            subtitle="企业动态"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                </div>
                <div class="col-lg-12 col-xl-6 col-12">
                    <SectionTitle
                            text-align="center"
                            subtitle="行业政策"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                </div>
            </div>
            <div class="container">
                <div class="col-lg-12 col-xl-6 col-12">
                    <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                </div>
                <div class="col-lg-12 col-xl-6 col-12">
                    <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                </div>
            </div>
        </div> -->
        <!-- <div class="rn-accordion-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-1">
                        <SectionTitle
                            text-align="center"
                            subtitle="企业动态"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--12">
                    <div class="col-lg-6 offset-lg-1">
                        <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 offset-lg-1">
                        <SectionTitle
                            text-align="center"
                            subtitle="行业政策"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--6">
                    <div class="col-lg-10 offset-lg-1">
                        <Accordion3
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div> -->
        
        <Separator/>

        <!-- <div class="rn-accordion-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            text-align="center"
                            subtitle="行业政策"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--20">
                    <div class="col-lg-10 offset-lg-1">
                        <Accordion3
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div> -->
        
        
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
import Accordion from '../components/elements/accordion/Accordion2'
import Accordion3 from '../components/elements/accordion/Accordion3'
import Separator from '../components/elements/separator/Separator'


    import axios from 'axios'
import AboutFour from '../components/elements/about/AboutFour'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Gallery from "../components/elements/gallery/Gallery"
import Portfolio from '../components/elements/portfolio/Portfolio'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import ServiceFour from '../components/elements/service/ServiceFour'
import PortfolioItemMixin from '../mixins/PortfolioItemMixin'

    export default {
        name: 'PortfolioPage',
        components: {Portfolio, SectionTitle, Layout, Breadcrumb, AboutFour, ServiceFour,Separator,Gallery,Accordion,Accordion3},
        mixins: [PortfolioItemMixin],
        data() {
            return {
                list: [],
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                axios.get(this.$urls+'get_news').then(
					response => {
                        if(response.data.status==200){
                            this.list = response.data.data.list
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
            }

        }
    }
</script>
<style scoped>
@keyframes wrapper-gradient {
        100% {
            transform: translateY(0);
        }
        0% {
            transform: translateY(100%);
        }
    }
    .bg_image {
      animation: wrapper-gradient 0.5s linear;
    }
</style>