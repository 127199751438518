<template>
    <Layout>

        <!-- <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="药房介绍"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Gallery :gallery-data="list.robot" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="1"
             :style="'background-image: url('+list.background+')'">
            <div>
                <!-- <div class="row radius-10 align-items-center">
                    <div class="col-lg-12 col-xl-1 col-12">
                        
                    </div>
                    <div class="col-lg-12 col-xl-4 col-12">
                        <div id="china_map_box" class="gradient-wrapper">
                            <img src="@/assets/img/robot.png" alt="Split Image"/>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-1 col-12">
                        
                    </div>
                    <div class="col-lg-12 col-xl-6 col-12 robot_text">
                        <div class="split-inner" v-if="show_text">
                            <h4 class="title" style="color:#fff;">通济智医·智慧无人药房平台</h4>
                            <p class="description" style="color:#ddd;">以消费医疗为基础，面对医疗机构-患者-企业-居家养老-社区-基层等提供便民的医疗服务平台；
                            <br/>通济智医将全国健康医疗资源与区域内医疗资源相互连接，建设智慧无人药房平台；
                            <br/>以地区核心医院为中心，建设覆盖全域的医疗服务共同体。</p>
                            <ul class="split-list">
                                <li style="color:#ccc;">顺应时代大背景，可创新患者购买方式，提高医院便民服务水平；</li>
                                <li style="color:#ccc;">顺应国家医改政策，可应对处方外流趋势，可降低医院耗占比；</li>
                                <li style="color:#ccc;">与医院现有产品形成互补，实现患者“一站式”服务，在医院院内实现全部产品的购买；</li>
                                <li style="color:#ccc;">全天候开放，满足 24小时患者便民产品购买需求，提高医院智慧医疗服务形象，改善就医环境。</li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <Separator/>
        <div class="rn-service-area rn-section-gap" v-if="list.company">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle2 :tab-data="list.company" data-aos-delay="60" data-aos="fade-up"/>
                    </div>
                </div>
                
                
            </div>
        </div>

        <!-- <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row no-gutters radius-10 align-items-center">
                    <div class="col-lg-12 col-xl-5 col-12">
                        <div id="china_map_box">
                            <img :src="list.robot" alt="Split Image"/>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-7 col-12">
                        <div class="split-inner">
                            <h4 class="title">通济智医·智慧无人药房平台</h4>
                            <p class="description">以消费医疗为基础，面对医疗机构-患者-企业-居家养老-社区-基层等提供便民的医疗服务平台；
<br/>通济智医将全国健康医疗资源与区域内医疗资源相互连接，建设智慧无人药房平台；
<br/>以地区核心医院为中心，建设覆盖全域的医疗服务共同体。</p>
                            <ul class="split-list">
                                <li>顺应时代大背景，可创新患者购买方式，提高医院便民服务水平；</li>
                                <li>顺应国家医改政策，可应对处方外流趋势，可降低医院耗占比；</li>
                                <li>与医院现有产品形成互补，实现患者“一站式”服务，在医院院内实现全部产品的购买；</li>
                                <li>全天候开放，满足 24小时患者便民产品购买需求，提高医院智慧医疗服务形象，改善就医环境。</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <Separator/>
        
        <section class="rn-service-area rn-section-gap" v-if="list.advantage">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :subtitle="list.advantage.title"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <ServiceFour
                    service-style="service__style--1 icon-circle-style with-working-process"
                    text-align="center"
                    :service-list="list.advantage.content"
                />
            </div>
        </section>

        <!-- <Separator/> -->

        <!-- <AboutFour v-if="list.background" :image="list.background.image" :video="list.background.video" :data-list="list.background.list"/> -->
        
    </Layout>
</template>

<script>
    import axios from 'axios'
import Layout from '../components/common/Layout'
import AboutFour from '../components/elements/about/AboutFour'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Gallery from "../components/elements/gallery/Gallery"
import Portfolio from '../components/elements/portfolio/Portfolio'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import SectionTitle2 from '../components/elements/sectionTitle/SectionTitle2'
import Separator from '../components/elements/separator/Separator'
import ServiceFour from '../components/elements/service/ServiceFour'
import PortfolioItemMixin from '../mixins/PortfolioItemMixin'

    export default {
        name: 'PortfolioPage',
        components: {Portfolio, SectionTitle,SectionTitle2, Layout, Breadcrumb, AboutFour, ServiceFour,Separator,Gallery},
        mixins: [PortfolioItemMixin],
        data() {
            return {
                list: [],
                show_text: false,
            }
        },
        created() {
            this.init()
            setTimeout(() => {
                this.show_text = true;
            }, 300);
        },
        methods: {
            init() {
                axios.get(this.$urls+'get_portfolio').then(
					response => {
                        if(response.data.status==200){
                            this.list = response.data.data.list
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
            }

        }
    }
</script>
<style scoped>
@keyframes wrapper-gradient {
        100% {
            transform: translateY(0);
        }
        0% {
            transform: translateY(100%);
        }
    }
    @keyframes img-gradient {
      100% {
        transform: translateX(0);
      }
      0% {
        transform: translateX(-100%);
      }
    }
    /* .gradient-wrapper>img {
      animation: wrapper-gradient 2s linear;
    }
    .robot_text {
      animation: wrapper-gradient 2s linear;
    } */
    .bg_image {
        animation: wrapper-gradient 0.5s linear;
    }
</style>