<template>
    <Layout>

        <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="1"
             :style="'background-image: url('+list.background+')'">
            <div>
            </div>
        </div>

        <Separator />

        <div class="mt--50">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="部分客户案例"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <Case class="mt--20" :gallery-data="list.kehu"/>
            </div>
        </div>
        <Separator />

        
        <!-- Start Brand Area  -->
        <div class="rwt-brand-area mb--50 mt--40">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="部分药房实景"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Gallery :gallery-data="list.robot" column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Brand Area  -->
    </Layout>
</template>

<script>
    import axios from 'axios'
import Layout from '../components/common/Layout'
import Brand from "../components/elements/brand/Brand"
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Case from '../components/elements/case/Case'
import Gallery from "../components/elements/gallery/Gallery4"
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Separator from '../components/elements/separator/Separator'
import PortfolioItemMixin from '../mixins/PortfolioItemMixin'

    export default {
        name: 'PortfolioPage',
        components: {Case, SectionTitle, Layout, Breadcrumb,Brand,Gallery,Separator},
        mixins: [PortfolioItemMixin],
        data() {
            return {
                list: [],
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                setTimeout(() => {
                    axios.get(this.$urls+'get_case').then(
                        response => {
                            if(response.data.status==200){
                                this.list = response.data.data.list
                            }
                        },
                        error => {
                            //请求后更新List的数据
                        }
                    )
                }, 800);
            }

        }
    }
</script>
<style scoped>
@keyframes wrapper-gradient {
        100% {
            transform: translateY(0);
        }
        0% {
            transform: translateY(100%);
        }
    }
    .bg_image {
      animation: wrapper-gradient 0.5s linear;
    }
</style>