<template>
    <!-- <Layout header-class="header-transparent" :show-newsletter="false" :show-cta="false"> -->
    <Layout>
        <!-- Start Slider Area   data-black-overlay="0" -->
        <div class="slider-area slider-style-1 height-850 bg_image"
             
             :style="{'background-image': `url(${require(`@/assets/img/gywm.png`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <!-- <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150" v-if="list.title" v-html="list.title">
                            </h1> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        

        <!-- <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row mt_dec--30">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div class="col-lg-6 mt--30"
                                 data-aos="slide-up"
                                 data-aos-duration="800"
                                 :data-aos-delay="100 + index"
                                 v-for="(blog, index) in posts"
                                 :key="index">
                                <BlogPost :blog="blog" blog-post-style="list"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rwt-timeline-area rn-section-gap" v-if="list.company">
            <div class="container">
                <Tab2 :tab-data="list.company" :tab-style="2" data-aos="fade-up"/>
            </div>
        </div>

        

        

        <!-- <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="创始人介绍"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData"
                         :key="index">
                        <Team v-if="teamMember.name" :team-member="teamMember"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Service Area -->

        <!-- Start Brand Area -->
        <!-- <div class="rwt-brand-area pb--60 pt--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Brand :brand-list="brandList" :brand-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Brand Area -->

        <Separator/>

        <!-- Start Service Area -->
        <!-- <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="What we can do for you"
                            title="Services provide for you."
                            description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </div> -->
        <!-- End Service Area -->
        <div class="rwt-timeline-area rn-section-gap" v-if="list.history.title">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :subtitle="list.history.title"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="list.history.content" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div>

        <Separator/>
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="企业资质"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <!-- <Gallery5 :gallery-data="galleryData" data-aos="fade-up"></Gallery5> -->
                <swiper ref="mySwiper" data-aos="fade-up" :options="swiperOptions">
                    <swiper-slide v-for="(item,index) in galleryData">
                        <div class="reason-item">
                            <img @click="imageIndex = index" :src="item.src" alt="" class="deck-img" width="100%" style="cursor:pointer;">
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
        <Separator/>

        <div class="rwt-timeline-area rn-section-gap" v-if="list.posts.title">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :subtitle="list.posts.title"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div class="col-lg-6"
                                 data-aos="slide-up"
                                 data-aos-duration="800"
                                 :data-aos-delay="100 + index"
                                 v-for="(blog, index) in list.posts.content"
                                 :key="index">
                                <BlogPost :blog="blog" blog-post-style="list"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->

        

        <!-- Start Elements Area -->
        <!-- <div class="rwt-counterup-area pb--100">
            <div class="container mt_dec--30">
                <Counter
                    :counter-style="5"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-3 col-md-6 col-sm-6 col-12"
                />
            </div>
        </div> -->
        <!-- End Elements Area -->

        <CoolLightBox
            :items="galleryData"
            :index="imageIndex"
            @close="imageIndex = null">
        </CoolLightBox>
    </Layout>
</template>

<script>
    import axios from 'axios'
import BlogPost from '../components/blog/BlogPost2'
import Layout from '../components/common/Layout'
import AboutFour from '../components/elements/about/AboutFour'
import Brand from '../components/elements/brand/Brand'
import Counter from '../components/elements/counterUp/Counter'
import Gallery5 from "../components/elements/gallery/Gallery5"
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Separator from '../components/elements/separator/Separator'
import ServiceFour from '../components/elements/service/ServiceFour'
import ServiceOne from '../components/elements/service/ServiceOne'
import Tab from '../components/elements/tab/Tab'
import Tab2 from '../components/elements/tab/Tab2'
import Team from '../components/elements/team/Team'
import Timeline from '../components/elements/timeline/Timeline'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout, BlogPost, Tab, ServiceFour, Tab2, Gallery5},
        data() {
            return {
                list: [],
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },

                ],
                galleryData: [],
                imageIndex: null,
                swiperOptions: {
                    // pagination: {
                    //     el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                    //     clickable: true,            //轮播按钮支持点击
                    // },
                    // //自动播放
					// autoplay: {
					// 	delay: 4000,
					// 	disableOnInteraction: false
					// },
					// //循环
					// loop:true,
                    // navigation: {
                    //     nextEl: '.swiper-button-next',
                    //     prevEl: '.swiper-button-prev',
                    // },
                    effect : 'coverflow',
                    slidesPerView: 4,
                    centeredSlides: true,
                    loop:true,
                    coverflow: {
                        rotate: 3,
                        stretch: 20,
                        depth: 10,
                        modifier: 1,
                        slideShadows : true
                    },
                    pagination: {
                        el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                        clickable: true,            //轮播按钮支持点击
                    },
                    autoplay: {
						delay: 2000,
						disableOnInteraction: false
					},
                    // pagination : '.swiper-pagination'
                },
                timelineData: [
                    {
                        id: '1',
                        title: '2020年12月4日',
                        description: '贵州通济智医科科技有限公司成立',
                    },
                    {
                        id: '2',
                        title: '2021年4月',
                        description: '第一台智慧无人药房进入市场',
                    },
                    {
                        id: '3',
                        title: '2021年8月',
                        description: '全国首家无感染智慧无人药房上市',
                    },
                    {
                        id: '4',
                        title: '2022年8月',
                        description: '公司被评为贵州省科技型中小企业AAA级信用企业',
                    },
                    {
                        id: '5',
                        title: '2022年12月',
                        description: '公司已自主完成10个专利11个软著知识产权的申报',
                    },
                    {
                        id: '6',
                        title: '2023年1月',
                        description: '获得鑫资本集团-香港永安达集团Pre-A轮数千万融资',
                    },
                    {
                        id: '7',
                        title: '2023年3月',
                        description: '广州通济智医分公司成立，完成ISO9001质量管理体系认证',
                    },
                    {
                        id: '8',
                        title: '2023年-2025年',
                        description: '计划完成线上诊疗+线下购买一体化物联网医疗+新零售平台建成',
                    },
                ],
                counterData: [
                    {
                        number: 30,
                        title: '三甲',
                    },
                    {
                        number: 11,
                        title: '三级其他',
                    },
                    {
                        number: 74,
                        title: '二甲',
                    },
                    {
                        number: 22,
                        title: '二级其他',
                    }
                ],
                tabData: {
                    tabImage: 'bg.png',
                    tabContent: [
                        {
                            id: 1,
                            menu: '管理系统',
                            content: `<p>公司着力于研发医疗产品零售终端自助设备和智慧医药SaaS软件平台技术，利用物联网技术打通“人与社区/乡镇-医院/医生-药店/药房”医药产品流通信息化管理系统。</p>`,
                        },
                        {
                            id: 2,
                            menu: '物联网',
                            content: `<p>创新的医疗产品零售新模式不仅为医药零售企业、医疗机构、配送企业等提供互联网+药品流通物联网整体解决方案。</p>`,
                        },
                        {
                            id: 3,
                            menu: '惠民便民',
                            content: `<p>为医保控费、药品监管、基层三医联动、疫情防控及社区康养慢病管理提供切实可行的项目解决方案，提供惠民便民的健康消费生活模式。</p>`,
                        }
                    ]
                },
                posts: [
                    {
                        id: '1',
                        img: 'https://inside.tongjizhiyi.com/uploads/10001/20230601/34285f1ffe3aebdd55c8a51e328cb32c.jpg',
                        authorId: '1',
                        title: '企业核心价值观',
                        excerpt: ['以人为本','服务至上','创新引领'],
                    },
                    {
                        id: '2',
                        img: 'https://inside.tongjizhiyi.com/uploads/10001/20230601/19bad8fedfc99b57017f271134406942.jpg',
                        authorId: '2',
                        title: '企业理念',
                        excerpt: ['以健康为使命','以科技为基础','以社会为己任'],
                    },
                    {
                        id: '3',
                        img: 'https://inside.tongjizhiyi.com/uploads/10001/20230601/d1fe88e514feeda051eb9146031fbc7c.jpg',
                        authorId: '3',
                        title: '企业精神',
                        excerpt: ['创新驱动','诚信立业','服务社会'],
                    },
                    {
                        id: '4',
                        img: 'https://inside.tongjizhiyi.com/uploads/10001/20230601/b9c7196e5c476d50f300cf3d8e0ec951.jpg',
                        authorId: '4',
                        title: '管理理念',
                        excerpt: ['科学管理','人文关怀','共享成果'],
                    },
                ],
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                axios.get(this.$urls+'get_about').then(
					response => {
                        if(response.data.status==200){
                            this.list = response.data.data.list
                            this.galleryData = response.data.data.galleryData
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
                // axios.get(this.$urls+'get_team').then(
				// 	response => {
                //         if(response.data.status==200){
                //             this.teamData = response.data.data.teamData
                //             this.teamData2 = response.data.data.teamData2??[]
                //         }else{
                //             this.result = response.data.message
                //         }
				// 	},
				// 	error => {
				// 		//请求后更新List的数据
				// 	}
				// )
            }
        }
    }
</script>
<style lang="scss" scoped>
@keyframes wrapper-gradient {
    100% {
    transform: translateY(0);
    }
    0% {
    transform: translateY(100%);
    }
}
.bg_image {
    animation: wrapper-gradient 0.5s linear;
}
</style>